import './App.css';
import PageHeader from './Components/PageHeader';
import Footer from './Footer';

export default function TermsPage() {

    return (
        <div className="bg-white">
            <PageHeader title="Terms of Use" subtitle="" />

            <div className="bg-white px-6 py-3 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <p>This mobile application (the "App", the "Application", "FindFlix") is made available by FindFlix
                        Holdings Pty Ltd, a company registered in South Africa with registration number 2023/709067/07 ("FindFlix",
                        "FindFlix Holdings", "us", "we" or "our").</p>

                    <p>You, the user of the App, confirm your acceptance of these App terms of use ("App Terms"). If you do
                        not agree to these App Terms, you must immediately uninstall the App and discontinue its use. These Terms
                        of Use should be read alongside our Privacy Policy.</p>

                    <h3>App and Related Terms</h3>

                    <p>Depending on the version of the Application you have downloaded, these App Terms incorporate Apple or
                        Google's terms and conditions and privacy policies ("Platform Terms"). If there is any conflict
                        between these App Terms and the Platform Terms then these App Terms will prevail.</p>

                    <p>We may from time to time vary these App Terms. Please check these App Terms regularly to ensure you are
                        aware of any variations made by us. If you continue to use this App, you are deemed to have accepted such
                        variations. If you do not agree to such variations, you should not use the App.</p>

                    <h3>Use of the App</h3>

                    <p>You must be at least 16 years of age to use the App.</p>

                    <p>FindFlix Holdings hereby grants you a non-exclusive, non-transferable, revocable licence to use the App for
                        your personal, non-commercial use and only on an Apple or Android device ("Device") as permitted by the
                        applicable Platform Terms and in accordance with these App Terms ("User Licence").  All other rights in
                        the App are reserved by FindFlix Holdings.</p>

                    <p>In the event of your breach of these App Terms we will be entitled to terminate the User Licence immediately.</p>

                    <p>You acknowledge that your agreement with your mobile network provider ("Mobile Provider") will apply to your
                        use of the App. You acknowledge that you may be charged by the Mobile Provider for data services while using
                        certain features of the App and you accept responsibility for such charges. If you are not the bill payer for
                        the Device being used to access the App, you will be assumed to have received permission from the bill payer
                        for using the App.</p>

                    <h3>Intellectual Property</h3>

                    <p>The FindFlix name, and logo, and other FindFlix Holdings trademarks, service marks, graphics and logos used in
                        connection with the App are copyrights of FindFlix Holdings. Other trademarks, service marks, graphics and logos
                        used in connection with the App are the trademarks of their respective owners (collectively "Third Party Trademarks").
                        The FindFlix copyrights and Third Party Trademarks may not be copied, imitated or used, in whole or in part,
                        without the prior written permission of FindFlix Holdings or the applicable trademark holder. The App and the content
                        featured in the App are protected by copyright, trademark, patent and other intellectual property and proprietary
                        rights which are reserved to FindFlix Holdings and its licensors.</p>

                    <h3>Prohibited Uses</h3>

                    <p>You agree not to use the App in any way that:</p>

                    <ul>
                        <li>is unlawful, illegal or unauthorised;</li>
                        <li>is defamatory of any other person;</li>
                        <li>is obscene or offensive;</li>
                        <li>promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                        <li>infringes any copyright, database right or trade mark of any other person;</li>
                        <li>is likely to harass, upset, embarrass, alarm or annoy any other person;</li>
                        <li>is likely to disrupt our service in any way; or</li>
                        <li>advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
                    </ul>

                    <h3>Indemnification</h3>

                    <p>You agree to indemnify FindFlix Holdings for any breach of these App Terms. FindFlix Holdings reserves the
                        right to control the defence and settlement of any third party claim for which you indemnify FindFlix Holdings
                        under these App Terms and you will assist us in exercising such rights.</p>

                    <h3>No Promises</h3>

                    <p>FindFlix Holdings provides the App on an 'as is' and 'as available' basis without any promises or representations,
                        express or implied. In particular, FindFlix Holdings does not warrant or make any representation regarding the
                        validity, accuracy, reliability or availability of the App or its content.</p>

                    <p>To the fullest extent permitted by applicable law, FindFlix Holdings hereby excludes all promises, whether
                        express or implied, including any promises that the App is fit for purpose, of satisfactory quality, non-infringing,
                        is free of defects, is able to operate on an uninterrupted basis, that the use of the App by you is in compliance
                        with laws or that any information that you transmit in connection with this App will be successfully, accurately
                        or securely transmitted.</p>

                    <h3>Exclusion of FindFlix's Liability</h3>

                    <p>Nothing in these App Terms shall exclude or in any way limit FindFlix Holdings' liability for death or personal
                        injury caused by its negligence or for fraud or any other liability to the extent the same may not be excluded or
                        limited as a matter of law. To the fullest extent permitted under applicable law, in no event shall FindFlix Holdings
                        be liable to you with respect to use of the App and/or be liable to you for any direct, indirect, special or
                        consequential damages including, without limitation, damages for loss of goodwill, lost profits, or loss, theft or
                        corruption of your information, the inability to use the App, Device failure or malfunction.</p>

                    <p>FindFlix Holdings shall not be liable even if it has been advised of the possibility of such damages, including
                        without limitation damages caused by error, omission, interruption, defect, failure of performance, unauthorised
                        use, delay in operation or transmission, line failure, computer virus, worm, Trojan horse or other harm.</p>

                    <p>In the event that applicable law does not allow the exclusion of certain promises and/or the exclusion of
                        liability for direct, indirect, consequential or other damages, in no event shall FindFlix Holdings' liability arising
                        under or in connection with these App Terms and your use of the App exceed USD50.</p>

                    <h3>General</h3>

                    <p>These App Terms shall be governed by the laws of the Republic of South Africa and the parties submit to the exclusive
                        jurisdiction of the courts of the Republic of South Africa to resolve any dispute between them arising under or in
                        connection with these App Terms.</p>

                    <p>If any provision (or part of a provision) of these App Terms is found by any court or administrative body of
                        competent jurisdiction to be invalid, unenforceable or illegal, such term, condition or provision will to that
                        extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the
                        fullest extent permitted by law.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}